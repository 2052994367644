























































































import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
} from "@vue/composition-api";
import { t, tc } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomText,
  AtomButton,
  AtomButtonTypeEnum,
  AtomButtonSizeEnum,
  MolPagination,
  MolSearchBox,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomLoading,
  MolIframeModal,
} from "@/v2/new-design-system";
import { CustomerList } from "./../list";
import {
  CustomerTabFilterTypeEnum,
  fetchCustomers,
  ICustomer,
  IFetchCustomersResponse,
} from "@/v2/repo/customer-management/fetch-customers";
import { report } from "@chatfood/bug-reporter";
import { ICustomerManagementRouteEnum } from "@/v2/module/customer-management/customer-mangement-route.enum";
import { useHelpers } from "@/v2/composable/use-helpers";
import { countries } from "@/v2/new-design-system/mol/country-code-select/countries";
import { crmRepo } from "@/module/crm/repo";
import { IRepoCreateCustomersReportRequest } from "@/module/crm/repo/create-customers-report";
import { Toast } from "@/design-system";
import { IAudience } from "@/module/crm/domain/audience";
import { generalErrorToast } from "@/v2/util/general-error-toast";

const css = bemBuilder("customer-management-info");

type ISortDirection = "ASC" | "DESC";

export default defineComponent({
  name: "CustomerManagementInfo",
  components: {
    AtomText,
    AtomLoading,
    MolPagination,
    MolSearchBox,
    CustomerList,
    AtomButton,
    MolIframeModal,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    tabName: {
      type: String as PropType<ICustomerManagementRouteEnum>,
      required: true,
    },
  },
  setup(props) {
    const isTableLoading = ref(false);
    const isNewMessageModalVisible = ref(false);
    const isLoading = ref(false);
    const isCreatingReport = ref(false);
    const searchInput = ref();
    const customerList = ref<Array<ICustomer>>([]);
    const canShowListing = ref(false);
    const pagination = ref<IFetchCustomersResponse["paginator"]>();

    const sortBy = ref("lastTransactionAt");
    const sortColumnDirection = ref<ISortDirection>("DESC");

    const hasItems = computed(() => Boolean(pagination.value?.total));

    const filter = computed(() => {
      const filterMap = new Map<
        ICustomerManagementRouteEnum,
        CustomerTabFilterTypeEnum
      >([
        [ICustomerManagementRouteEnum.RECENT, CustomerTabFilterTypeEnum.RECENT],
        [
          ICustomerManagementRouteEnum.INACTIVE,
          CustomerTabFilterTypeEnum.INACTIVE,
        ],
        [ICustomerManagementRouteEnum.BEST, CustomerTabFilterTypeEnum.BEST],
        [
          ICustomerManagementRouteEnum.CRITICAL,
          CustomerTabFilterTypeEnum.CRITICAL,
        ],
      ]);

      return filterMap.get(props.tabName);
    });

    const business = useHelpers().getCurrentBusiness(props.businessId) || {};

    const country = computed(() => {
      return business?.country;
    });

    const businessName = computed(() => {
      return business?.name;
    });

    async function startCampaign() {
      const typeMap = new Map<ICustomerManagementRouteEnum, IAudience>([
        [ICustomerManagementRouteEnum.ALL, "all"],
        [ICustomerManagementRouteEnum.RECENT, "recent"],
        [ICustomerManagementRouteEnum.INACTIVE, "inactive"],
        [ICustomerManagementRouteEnum.BEST, "best"],
        [ICustomerManagementRouteEnum.CRITICAL, "critical"],
      ]);

      try {
        await crmRepo.startCampaignFromAudience({
          businessId: props.businessId,
          audience: typeMap.get(props.tabName) || "all",
        });

        new Toast().create({
          type: "success",
          text: t(
            "module.customer_management.export_marketing_campaign.succes_toast"
          ),
        });
      } catch (e) {
        report(e);
        generalErrorToast();
      }
    }

    async function fetchCustomerList(
      page = 1,
      sort?: string,
      sortDirection?: ISortDirection
    ) {
      try {
        isTableLoading.value = true;

        const result = await fetchCustomers({
          businessId: props.businessId,
          page,
          tabFilter: filter.value,
          search: searchInput.value,
          sort,
          sortDirection,
        });

        customerList.value = result.data;
        pagination.value = result.paginator;
      } catch (e) {
        report(e);
      } finally {
        isTableLoading.value = false;
      }
    }

    async function createReport(): Promise<void> {
      isCreatingReport.value = true;

      const typeMap = new Map<
        ICustomerManagementRouteEnum,
        IRepoCreateCustomersReportRequest["type"]
      >([
        [ICustomerManagementRouteEnum.RECENT, "recentCustomers"],
        [ICustomerManagementRouteEnum.INACTIVE, "inactiveCustomers"],
        [ICustomerManagementRouteEnum.BEST, "bestCustomers"],
        [ICustomerManagementRouteEnum.CRITICAL, "criticalFeedbacks"],
      ]);

      try {
        await crmRepo.createCustomersReport({
          businessId: props.businessId,
          type: typeMap.get(props.tabName) || "allCustomers",
        });

        new Toast().create({
          type: "success",
          text: t(
            "module.customer_management.main.export_report.toast_success_message"
          ),
        });
      } catch (e) {
        generalErrorToast();
      } finally {
        isCreatingReport.value = false;
      }
    }

    function onSearchChange(input: string) {
      searchInput.value = input;
      fetchCustomerList(1, sortBy.value, sortColumnDirection.value);
    }

    function onNavigate(page: number) {
      fetchCustomerList(page, sortBy.value, sortColumnDirection.value);
    }

    function onSearchFocus() {
      if (searchInput.value) return;

      const { dialCode } =
        countries.find((item) => country.value === item.country) || {};

      if (!dialCode) return;

      searchInput.value = `+${dialCode}`;
    }

    function onNewMessage() {
      isNewMessageModalVisible.value = true;
    }

    onMounted(async () => {
      isLoading.value = true;
      try {
        await fetchCustomerList(1, sortBy.value, sortColumnDirection.value);
        canShowListing.value = Boolean(customerList.value.length);
      } finally {
        isLoading.value = false;
      }
    });

    onUnmounted(() => {
      searchInput.value = null;
    });

    function onSort(key: string, sortDirection: ISortDirection) {
      if (
        (!key && !sortDirection) ||
        (key === sortBy.value && sortColumnDirection.value === sortDirection)
      ) {
        return;
      }

      sortBy.value = key;
      sortColumnDirection.value = sortDirection;

      fetchCustomerList(pagination.value?.currentPage, key, sortDirection);
    }

    const newMessageIframeLink = computed(() => {
      return `https://chatfood.typeform.com/to/Fc6OqRNP?business_id=${
        props.businessId
      }&business_name=${encodeURIComponent(
        businessName.value
      )}&audience=${encodeURIComponent(props.tabName)}`;
    });

    return {
      t,
      tc,
      css,
      onSort,
      onNavigate,
      customerList,
      pagination,
      isLoading,
      isCreatingReport,
      hasItems,
      isTableLoading,
      canShowListing,
      searchInput,
      newMessageIframeLink,
      onSearchChange,
      onSearchFocus,
      onNewMessage,
      createReport,
      isNewMessageModalVisible,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButton,
      AtomButtonTypeEnum,
      AtomButtonSizeEnum,
      startCampaign,
    };
  },
});
