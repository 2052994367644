import { IRequest } from "@/module/crm/repo/create-customers-report/request.type";
import { fetcher } from "@/core/fetcher";
import { CrmRepoError } from "@/module/crm/repo/repo-error";
import { reportAndReturnUnexpectedError } from "@/module/common";

export type IRoutePath =
  | "all-customers"
  | "recent-customers"
  | "inactive-customers"
  | "best-customers"
  | "critical-feedbacks";

export const routePathMap: Record<IRequest["type"], IRoutePath> = {
  allCustomers: "all-customers",
  recentCustomers: "recent-customers",
  inactiveCustomers: "inactive-customers",
  bestCustomers: "best-customers",
  criticalFeedbacks: "critical-feedbacks",
};

export const createCustomersReport = async (
  request: IRequest
): Promise<void> => {
  try {
    await fetcher.post<void>(
      `api/v3/businesses/${request.businessId}/reports/${
        routePathMap[request.type]
      }`
    );
  } catch (err: unknown) {
    throw reportAndReturnUnexpectedError<CrmRepoError, CrmRepoError["code"]>(
      CrmRepoError,
      err,
      "createCustomersReport",
      "unknown"
    );
  }
};
