import camelcaseKeys from "camelcase-keys";
import { IApiContract } from "./api-contract.type";
import { ICustomer } from "../../domain/customer";

export const responseTransformer = (apiResponse: IApiContract): ICustomer => {
  return {
    ...camelcaseKeys(apiResponse, { deep: true }),
    name: apiResponse.name ?? undefined,
    email: apiResponse.email ?? undefined,
    averageRating: apiResponse.average_rating ?? undefined,
  };
};
