import {
  fetcher,
  FetcherHTTPError,
  FetcherConnectionLostError,
} from "@/core/fetcher";
import { CrmRepoError } from "../repo-error";
import { IApiContract } from "./api-contract.type";
import { responseTransformer } from "./response-transformer";
import { IRequest } from "./request.type";
import { ICustomer } from "../../domain/customer";
import { report } from "@chatfood/bug-reporter";

const THREE_MINUTES = 1000 * 60 * 3;

export async function fetchProfile(request: IRequest): Promise<ICustomer> {
  try {
    const res = await fetcher.get<IApiContract>(
      `crm/businesses/${request.businessId}/contacts/${request.customerId}`,
      {
        cacheTTL: THREE_MINUTES,
      }
    );

    return responseTransformer(res);
  } catch (err: unknown) {
    if (err instanceof FetcherHTTPError && err.status === 404) {
      throw new CrmRepoError(
        "fetchProfile",
        "Customer not found",
        "customer_not_found",
        err
      );
    }

    if (!(err instanceof FetcherConnectionLostError)) {
      const responseStatusCode =
        err instanceof FetcherHTTPError ? err.status : 1000;

      report(err, {
        tags: {
          "http.response_code": responseStatusCode,
        },
        context: {
          "Serialized error": JSON.stringify(err),
        },
      });
    }

    throw new CrmRepoError(
      "fetchProfile",
      "An unexpected error happened",
      "unknown",
      err
    );
  }
}
