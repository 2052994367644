import { http } from "@/v2/repo/http";
import {
  IFetchLoyaltyBalanceContract,
  IFetchLoyaltyBalanceRequest,
} from "@/v2/module/customer-management/repo/fetch-loyalty-balance/types";

export class FetchLoyaltyBalanceError extends Error {}

export const fetchLoyaltyBalance = async (
  request: IFetchLoyaltyBalanceRequest
): Promise<number> => {
  try {
    const { data } = await http.get<IFetchLoyaltyBalanceContract>(
      `/api/v3/businesses/${request.businessId}/reward/credit-balance/${request.phoneNumber}`
    );

    return data.balance;
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchLoyaltyBalanceError(e);
  }
};
