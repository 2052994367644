



























































import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import {
  AtomDate,
  AtomIcon,
  AtomText,
  AtomMoney,
  AtomTextTypeEnum,
  IOrgTableProps,
  OrgTable,
} from "@/v2/new-design-system";
import {
  ICustomer,
  ICustomerTransactionTypeEnum,
} from "@/v2/repo/customer-management/fetch-customers";
import { useRouter } from "@/router";
import { ICustomerManagementRouteEnum } from "@/v2/module/customer-management/customer-mangement-route.enum";

const css = bemBuilder("customer-list");

export default defineComponent({
  name: "CustomerList",
  components: {
    AtomDate,
    AtomIcon,
    AtomMoney,
    AtomText,
    OrgTable,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    customers: {
      type: Array as PropType<Array<ICustomer>>,
      required: true,
      default: Array,
    },
    onSort: {
      type: Function as PropType<
        (key: string, sortDirection: "NONE" | "ASC" | "DESC") => void
      >,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();

    const transactionTypeLabel = (
      transactionType: `${ICustomerTransactionTypeEnum}`
    ): string => {
      const mapTransactionTypeLabel: Record<string, any> = {
        pat: t("module.customer_management.transaction_types.pat"),
        oat: t("module.customer_management.transaction_types.oat"),
        payment_link: t(
          "module.customer_management.transaction_types.payment_link"
        ),
        delivery: t("module.customer_management.transaction_types.delivery"),
        pickup: t("module.customer_management.transaction_types.pickup"),
      };

      return mapTransactionTypeLabel[transactionType?.toLowerCase()];
    };

    const tableColumns: IOrgTableProps["columns"] = {
      name: {
        header: () => t("module.customer_management.list.name_column"),
        show: true,
        sortable: false,
        width: 200,
        sortDirection: "NONE",
      },
      phoneNumber: {
        header: () => t("module.customer_management.list.phone_number_column"),
        show: true,
        sortable: false,
        width: 200,
        sortDirection: "NONE",
      },
      email: {
        header: () => t("module.customer_management.list.email_column"),
        show: true,
        sortable: false,
        width: 200,
        sortDirection: "NONE",
      },
      transactionsCount: {
        header: () =>
          t("module.customer_management.list.transactions_count_column"),
        show: true,
        sortable: true,
        width: 200,
        sortDirection: "NONE",
      },
      amount: {
        header: () => t("module.customer_management.list.total_amount_column"),
        show: true,
        sortable: true,
        width: 200,
        sortDirection: "NONE",
      },
      lastTransactionAt: {
        header: () =>
          t("module.customer_management.list.last_transaction_date_column"),
        show: true,
        sortable: true,
        width: 250,
        sortDirection: "DESC",
      },
      averageRating: {
        header: () =>
          t("module.customer_management.list.average_rating_column"),
        show: true,
        sortable: true,
        width: 150,
        sortDirection: "NONE",
      },
    };

    const customersList = computed(() => {
      return props.customers.map((item) => ({
        ...item,
        uniqueId: item.id,
      }));
    });

    const openDetails = (customer: ICustomer) => {
      router.push({
        name: ICustomerManagementRouteEnum.DETAILS,
        params: {
          businessId: props.businessId,
          customerId: customer.id,
        },
      });
    };

    return {
      t,
      css,
      tableColumns,
      customersList,
      transactionTypeLabel,
      AtomTextTypeEnum,
      openDetails,
    };
  },
});
