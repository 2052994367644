import { http } from "@/v2/repo/http";
import {
  IFetchTransactionsBodyParams,
  IFetchTransactionsContract,
  IFetchTransactionsRequest,
  IFetchTransactionsResponse,
} from "@/v2/module/customer-management/repo/fetch-transactions/types";
import { IDeliveryPartner } from "@/v2/module/customer-management/domain/delivery-partner";
import { ITransactionStatus } from "@/v2/module/customer-management/domain/transaction-status";
import { match } from "ts-pattern";
import { ITransactionType } from "@/v2/module/customer-management/domain/transaction-type";
import { IDomainPaymentMethod } from "@/v2/domain/payment-method";

export const transactionTypeMap: Record<
  ITransactionType,
  IFetchTransactionsBodyParams["type"]
> = {
  dineIn: "OAT",
  delivery: "DELIVERY",
  pickup: "PICKUP",
  pat: "PAT",
  paymentLink: "PAYMENT_LINK",
};

export const paymentMethodTypeMap: Record<
  IFetchTransactionsContract["data"][0]["payment_method"]["type"],
  IDomainPaymentMethod
> = {
  Online: "ONLINE",
  Offline: "OFFLINE",
};

export const deliveryPartnerMap = new Map<
  IFetchTransactionsContract["data"][0]["delivery_partner"],
  IDeliveryPartner
>([
  ["quiqup", "quiqup"],
  ["oneclick", "oneclick"],
  ["careem_box", "careem"],
  ["swftbox", "swftbox"],
  ["jeebly", "jeebly"],
  ["yallow", "yallow"],
  ["flick", "flick"],
  ["stuart", "stuart"],
  ["ctf-shipox", "shipox"],
  ["ctf-innablink", "innablink"],
  ["ctf-hey-karry", "heyKarry"],
  ["ctf-talabat", "talabat"],
  ["ctf-careem", "chatfoodCareem"],
]);

export const transactionStatusMap: Record<
  IFetchTransactionsContract["data"][0]["status"],
  ITransactionStatus
> = {
  Complete: "complete",
  Cancelled: "cancelled",
};

const sortKeyHandler = (
  sortBy: IFetchTransactionsRequest["sort"]
): IFetchTransactionsBodyParams["sort"] =>
  match<
    IFetchTransactionsRequest["sort"],
    IFetchTransactionsBodyParams["sort"]
  >(sortBy)
    .with({ field: "uid", direction: "ASC" }, () => "reference_uid")
    .with({ field: "uid", direction: "DESC" }, () => "-reference_uid")
    .with({ field: "outlet", direction: "ASC" }, () => "outlet_name")
    .with({ field: "outlet", direction: "DESC" }, () => "-outlet_name")
    .with({ field: "amount", direction: "ASC" }, () => "amount")
    .with({ field: "amount", direction: "DESC" }, () => "-amount")
    .with({ field: "tipAmount", direction: "ASC" }, () => "tip_amount")
    .with({ field: "tipAmount", direction: "DESC" }, () => "-tip_amount")
    .with({ field: "paymentMethod", direction: "ASC" }, () => "payment_method")
    .with(
      { field: "paymentMethod", direction: "DESC" },
      () => "-payment_method"
    )
    .with(
      { field: "paymentDescription", direction: "ASC" },
      () => "payment_description"
    )
    .with(
      { field: "paymentDescription", direction: "DESC" },
      () => "-payment_description"
    )
    .with(
      { field: "deliveryPartner", direction: "ASC" },
      () => "delivery_partner"
    )
    .with(
      { field: "deliveryPartner", direction: "DESC" },
      () => "-delivery_partner"
    )
    .with({ field: "status", direction: "ASC" }, () => "status")
    .with({ field: "status", direction: "DESC" }, () => "-status")
    .with({ field: "rating", direction: "ASC" }, () => "rating")
    .with({ field: "rating", direction: "DESC" }, () => "-rating")
    .with({ field: "createdAt", direction: "ASC" }, () => "created_at")
    .with({ field: "createdAt", direction: "DESC" }, () => "-created_at")
    .otherwise(() => "-created_at");

export class FetchTransactionsError extends Error {}

/**
 * @link {https://chatfood.stoplight.io/docs/chatfood-api-dashboard/branches/main/crm/operations/list-crm-business-contact-transactions}
 */
export async function fetchTransactions(
  request: IFetchTransactionsRequest
): Promise<IFetchTransactionsResponse> {
  try {
    const params: IFetchTransactionsBodyParams = {
      type: transactionTypeMap[request.type],
      page: request.page,
      sort: sortKeyHandler(request.sort),
    };

    const { data } = await http.get<IFetchTransactionsContract>(
      `/crm/businesses/${request.businessId}/contacts/${request.contactId}/transactions`,
      {
        params,
      }
    );

    return {
      data: data.data.map((transaction) => ({
        id: transaction.id,
        uid: transaction.uid,
        outlet: transaction.outlet || undefined,
        amount: transaction.amount,
        tipAmount: transaction.tip_amount,
        paymentMethod: {
          name: transaction.payment_method.name,
          type: paymentMethodTypeMap[transaction.payment_method.type],
          description: transaction.payment_method.description || undefined,
        },
        deliveryPartner: deliveryPartnerMap.get(transaction.delivery_partner),
        status: transactionStatusMap[transaction.status],
        rating: transaction.rating || undefined,
        createdAt: new Date(transaction.created_at),
      })),
      paginator: {
        total: data.meta.total,
        perPage: data.meta.per_page,
        currentPage: data.meta.current_page,
        lastPage: data.meta.last_page,
      },
    };
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchTransactionsError(e);
  }
}
