import { IDeliveryPartner } from "@/v2/module/customer-management/domain/delivery-partner";
import { ITransactionStatus } from "@/v2/module/customer-management/domain/transaction-status";
import { IFetchTransactionsTransaction } from "@/v2/module/customer-management/repo/fetch-transactions";

type IPrice = Readonly<{
  currency: string;
  value: number;
}>;

export type ITransaction = {
  uniqueId: string;
  uid: number;
  outlet?: string;
  amount: IPrice;
  tipAmount: IPrice;
  paymentMethod: string;
  paymentDescription?: string;
  deliveryPartner?: IDeliveryPartner;
  status: ITransactionStatus;
  rating?: number;
  createdAt: Date;
};

export const fromRepository = (
  transaction: IFetchTransactionsTransaction
): ITransaction => ({
  uniqueId: transaction.id,
  uid: transaction.uid,
  outlet: transaction.outlet?.name,
  amount: transaction.amount,
  tipAmount: transaction.tipAmount,
  paymentMethod: transaction.paymentMethod.name,
  paymentDescription: transaction.paymentMethod.description,
  deliveryPartner: transaction.deliveryPartner,
  status: transaction.status,
  rating: transaction.rating,
  createdAt: transaction.createdAt,
});
