








import {
  defineComponent,
  getCurrentInstance,
  ref,
  computed,
  onMounted,
} from "@vue/composition-api";
import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import { OrgHeaderInfo, MolTabNavigation } from "@/v2/new-design-system";
import {
  getCountByCategories,
  IGetCountByCategoriesResponse,
} from "@/v2/repo/customer-management/get-count-by-categories";
import { ICustomerManagementRouteEnum } from "../customer-mangement-route.enum";
import { customerManagementTrack } from "@/v2/module/customer-management/track";
import { EventEnum } from "@/v2/enum";

const css = bemBuilder("customer-management-main");

export default defineComponent({
  name: "CustomerManagementMain",
  components: {
    OrgHeaderInfo,
    MolTabNavigation,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const counts = ref<IGetCountByCategoriesResponse>({
      all: 0,
      recent: 0,
      inactive: 0,
      best: 0,
      critical: 0,
    });

    const vm = getCurrentInstance();

    const tabNavigation = computed(() => [
      {
        count: counts.value.all,
        routeName: ICustomerManagementRouteEnum.ALL,
        title: t("module.customer_management.main.tab_all"),
      },
      {
        count: counts.value.recent,
        routeName: ICustomerManagementRouteEnum.RECENT,
        title: t("module.customer_management.main.tab_recent"),
        tooltip: t("module.customer_management.main.tab_recent_tooltip"),
      },
      {
        count: counts.value.inactive,
        routeName: ICustomerManagementRouteEnum.INACTIVE,
        title: t("module.customer_management.main.tab_inactive"),
        tooltip: t("module.customer_management.main.tab_inactive_tooltip"),
      },
      {
        count: counts.value.best,
        routeName: ICustomerManagementRouteEnum.BEST,
        title: t("module.customer_management.main.tab_best"),
        tooltip: t("module.customer_management.main.tab_best_tooltip"),
      },
      {
        count: counts.value.critical,
        routeName: ICustomerManagementRouteEnum.CRITICAL,
        title: t("module.customer_management.main.tab_critical"),
        tooltip: t("module.customer_management.main.tab_critical_tooltip"),
      },
    ]);

    onMounted(() => {
      fetchCounts();
      track(EventEnum.CUSTOMER_MANAGEMENT_VIEW_PAGE);
    });

    async function fetchCounts() {
      counts.value = await getCountByCategories(props.businessId);
    }

    function track(
      eventName: EventEnum,
      additionalData: Record<string, string> = {}
    ) {
      customerManagementTrack({
        // @ts-ignore
        analytics: vm?.proxy.$analytics,
        businessId: props.businessId,
        eventName,
        additionalData,
      });
    }

    return {
      t,
      css,
      counts,
      tabNavigation,
    };
  },
});
