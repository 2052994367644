var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('OrgTable',{class:_vm.css(),attrs:{"columns":_vm.tableColumns,"loading":_vm.isLoading,"has-bulk-selection":false,"has-actions":"","data":_vm.customersList,"data-test":"customers-table","total-records":_vm.customers.length,"on-sort":_vm.onSort,"no-records-msg":_vm.t('module.customer_management.list.empty_table'),"row-click":function (row) { return _vm.openDetails(row); }},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_c('AtomText',{attrs:{"data-test":("name-" + (row.phoneNumber))}},[_vm._v(" "+_vm._s(row.name ? row.name : _vm.t("module.customer_management.list.guest"))+" ")])]}},{key:"phoneNumber",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_vm._v(" "+_vm._s(cell)+" ")])]}},{key:"email",fn:function(ref){
var row = ref.row;
return [_c('AtomText',{attrs:{"data-test":("email-" + (row.phoneNumber))}},[_vm._v(" "+_vm._s(row.email)+" ")])]}},{key:"transactionsCount",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_vm._v(_vm._s(cell))])]}},{key:"amount",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_c('AtomMoney',{attrs:{"amount":cell.value,"currency":cell.currency}})],1)]}},{key:"lastTransactionAt",fn:function(ref){
var row = ref.row;
return [_c('div',{class:_vm.css('transaction')},[_c('AtomText',[_c('AtomDate',{attrs:{"datetime":row.lastTransactionAt,"format":"DD MMM YYYY"}})],1),_c('AtomText',{class:_vm.css('transaction-badge'),attrs:{"type":_vm.AtomTextTypeEnum.SMALL_BOLD}},[_vm._v(" "+_vm._s(_vm.transactionTypeLabel(row.lastTransactionType))+" ")])],1)]}},{key:"averageRating",fn:function(ref){
var cell = ref.cell;
return [(cell)?_c('div',{class:_vm.css('rating')},[_c('AtomIcon',{class:_vm.css('rating-icon'),attrs:{"size":14,"name":"rating-star"}}),_c('AtomText',[_vm._v(_vm._s(cell))])],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }