import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";
import { http } from "../../http";

export interface IGetCountByCategoriesResponse {
  all: number;
  recent: number;
  inactive: number;
  best: number;
  critical: number;
}

export class GetCountByCategoriesError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: GetCountByCategories");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function getCountByCategories(
  businessId: string
): Promise<IGetCountByCategoriesResponse> {
  let response: AxiosResponse;

  try {
    response = await http.get(
      `/crm/contact-categories-count-by-business/${businessId}`
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new GetCountByCategoriesError(error);
  }

  return {
    all: response.data.all,
    recent: response.data.recent,
    inactive: response.data.inactive,
    best: response.data.best,
    critical: response.data.critical,
  };
}
