import { fetcher } from "@/core/fetcher";
import type { IRequest } from "./request.type";
import { CrmRepoError } from "../repo-error";
import { reportAndReturnUnexpectedError } from "@/module/common";
import { IBody } from "./body.type";

export const startCampaignFromAudience = async (
  request: IRequest
): Promise<void> => {
  try {
    await fetcher.post<undefined, IBody>(
      `crm/businesses/${request.businessId}/contacts/export-to-marketing-campaign`,
      {
        body: {
          contact_category: request.audience,
        },
      }
    );
  } catch (err: unknown) {
    const throwableError = reportAndReturnUnexpectedError<
      CrmRepoError,
      CrmRepoError["code"]
    >(CrmRepoError, err, "startCampaignFromAudience", "unknown");
    throw throwableError;
  }
};
