var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css()},[_c('OrgTable',{class:_vm.css('list'),attrs:{"columns":_vm.tableColumns,"loading":_vm.isListLoading,"data":_vm.transactionList,"has-bulk-selection":false,"no-records-msg":_vm.t('module.customer_management.transactions.no_transactions'),"total-records":_vm.transactionList.length,"on-sort":_vm.onSort},scopedSlots:_vm._u([{key:"uid",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_c('strong',[_vm._v("#"+_vm._s(cell))])])]}},{key:"createdAt",fn:function(ref){
var cell = ref.cell;
return [_c('AtomDate',{attrs:{"datetime":cell,"format":"DD MMM YYYY, HH:mm"}})]}},{key:"outlet",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_vm._v(" "+_vm._s(cell)+" ")])]}},{key:"paymentMethod",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_vm._v(" "+_vm._s(cell)+" ")])]}},{key:"paymentDescription",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_vm._v(" "+_vm._s(cell)+" ")])]}},{key:"deliveryPartner",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_vm._v(" "+_vm._s(_vm.deliveryLabel(cell))+" ")])]}},{key:"status",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_vm._v(" "+_vm._s(_vm.transactionStatusLabel(cell))+" ")])]}},{key:"amount",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_c('AtomMoney',{attrs:{"amount":cell.value,"currency":cell.currency}})],1)]}},{key:"tipAmount",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_c('AtomMoney',{attrs:{"amount":cell.value,"currency":cell.currency}})],1)]}},{key:"rating",fn:function(ref){
var cell = ref.cell;
return [_c('MolRating',{attrs:{"rating":cell}})]}}])}),(_vm.hasItems)?_c('MolPagination',{class:_vm.css('pagination'),attrs:{"current-page":_vm.pagination.currentPage,"last-page":_vm.pagination.lastPage,"per-page":_vm.pagination.perPage,"total":_vm.pagination.total,"on-navigate":_vm.getTransactionList,"has-summary":false}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }