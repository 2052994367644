export type IDeliveryPartner =
  | "quiqup"
  | "oneclick"
  | "careem"
  | "swftbox"
  | "jeebly"
  | "yallow"
  | "flick"
  | "stuart"
  | "shipox"
  | "innablink"
  | "heyKarry"
  | "talabat"
  | "chatfoodCareem";

export const deliveryPartnerName: Record<IDeliveryPartner, string> = {
  oneclick: "One Click",
  quiqup: "Quiqup",
  careem: "Careem",
  swftbox: "swftbox",
  jeebly: "Jeebly",
  yallow: "Yallow",
  flick: "Flick",
  stuart: "Stuart",
  shipox: "Shipox",
  innablink: "Innablink",
  heyKarry: "Hey Karry",
  talabat: "Tabalat",
  chatfoodCareem: "Careem (by ChatFood)",
};
