import type {
  IFetchCustomerDetailsContract,
  IFetchCustomerDetailsRequest,
  IFetchCustomerDetailsResponse,
} from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { http } from "@/v2/repo/http";

export class FetchCustomerDetailsError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchCustomerDetails");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchCustomerDetails(
  request: IFetchCustomerDetailsRequest
): Promise<IFetchCustomerDetailsResponse> {
  try {
    const { data } = await http.get<IFetchCustomerDetailsContract>(
      `/crm/businesses/${request.businessId}/contacts/${request.contactId}`
    );
    return {
      name: data.name ?? undefined,
      email: data.email ?? undefined,
      phoneNumber: data.phone_number,
      averageRating: data.average_rating ?? undefined,
      averageSales: {
        value: data.average_sales.amount,
        currency: data.average_sales.currency,
      },
      totalSales: {
        value: data.total_sales.amount,
        currency: data.total_sales.currency,
      },
      transactionCount: data.transaction_count,
    };
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchCustomerDetailsError(error);
  }
}
