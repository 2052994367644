import { EventCategoryEnum } from "@/v2/enum/events";
import {
  analyticsEventCommonData,
  ITrackEvent,
  trackEvent,
} from "@/v2/util/analytics/trackEvent";

interface ICustomerManagementTrack {
  businessId: string;
}

export function customerManagementTrack({
  analytics,
  eventName,
  businessId,
  additionalData = {},
}: ITrackEvent & ICustomerManagementTrack): void {
  trackEvent({
    eventCategory: EventCategoryEnum.CUSTOMER_MANAGEMENT,
    analytics,
    eventName,
    additionalData: {
      ...analyticsEventCommonData([["business", businessId]]),
      ...additionalData,
    },
  });
}
