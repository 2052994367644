









































































































import { t } from "@/i18n";
import {
  ref,
  computed,
  onMounted,
  defineComponent,
  getCurrentInstance,
} from "@vue/composition-api";
import {
  bemBuilder,
  toMoney,
  phoneBeautifier,
  type ICurrency,
} from "@chatfood/core-utils";
import {
  AtomLoading,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomIcon,
  MolBackNav,
  MolMetricBox,
  MolTabNavigation,
} from "@/v2/new-design-system";
import { report } from "@chatfood/bug-reporter";
import { useHelpers } from "@/v2/composable/use-helpers";
import {
  fetchCustomerDetails,
  IFetchCustomerDetailsResponse,
} from "@/v2/repo/customer-management/fetch-customer-details";
import { customerManagementTrack } from "@/v2/module/customer-management/track";
import { EventEnum } from "@/v2/enum";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { ICustomerManagementRouteEnum } from "@/v2/module/customer-management/customer-mangement-route.enum";
import { fetchLoyaltyBalance } from "@/v2/module/customer-management/repo/fetch-loyalty-balance";
import { useRouter } from "@/router";

const css = bemBuilder("customer-management-details");

export default defineComponent({
  name: "CustomerManagementDetails",
  components: {
    AtomIcon,
    AtomText,
    AtomLoading,
    MolBackNav,
    MolMetricBox,
    MolTabNavigation,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    customerId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(true);
    const customerInfo = ref<IFetchCustomerDetailsResponse>();
    const loyaltyBalance = ref("");
    const vm = getCurrentInstance();
    const router = useRouter();

    const business = computed(
      () => useHelpers().getCurrentBusiness(props.businessId) || {}
    );

    function moneyFormatter(
      amount = 0,
      currency = business.value.currency?.code as ICurrency
    ) {
      return toMoney(amount, currency, {
        locale: business?.value.language,
      });
    }

    const totalSales = computed(() => {
      const amount = customerInfo.value?.totalSales.value;
      const currency = customerInfo.value?.totalSales.currency;
      return moneyFormatter(amount, currency as ICurrency);
    });

    const averageRating = computed(() => {
      const rating = customerInfo.value?.averageRating || "-";
      return `${rating}`;
    });

    const averageSales = computed(() => {
      const amount = customerInfo.value?.averageSales.value;
      const currency = customerInfo.value?.averageSales.currency;
      return moneyFormatter(amount, currency as ICurrency);
    });

    const transactions = computed(() => {
      const transactions = customerInfo.value?.transactionCount || 0;
      return `${transactions}`;
    });

    const getCustomerInfo = async () => {
      try {
        customerInfo.value = await fetchCustomerDetails({
          businessId: props.businessId,
          contactId: props.customerId,
        });
      } catch (e) {
        generalErrorToast(t("module.customer_management.details.not_found"));
        report(e);
        router.push({
          name: ICustomerManagementRouteEnum.ALL,
          params: {
            businessId: props.businessId,
          },
        });
      }
    };

    const getLoyaltyBalance = async (phoneNumber: string) => {
      try {
        const creditBalance = await fetchLoyaltyBalance({
          businessId: props.businessId,
          phoneNumber: phoneNumber,
        });

        loyaltyBalance.value = moneyFormatter(creditBalance);
      } catch (e) {
        report(e);
      }
    };

    const tabTransactionsNavigation = computed(() => [
      {
        count: 0,
        routeName: ICustomerManagementRouteEnum.DINE_IN_TRANSACTIONS,
        title: t("module.customer_management.details.list_nav_dine_in"),
      },
      {
        count: 0,
        routeName: ICustomerManagementRouteEnum.DELIVERY_TRANSACTIONS,
        title: t("module.customer_management.details.list_nav_delivery"),
      },
      {
        count: 0,
        routeName: ICustomerManagementRouteEnum.PICKUP_TRANSACTIONS,
        title: t("module.customer_management.details.list_nav_pickup"),
      },
      {
        count: 0,
        routeName: ICustomerManagementRouteEnum.PAYMENT_LINK_TRANSACTIONS,
        title: t("module.customer_management.details.list_nav_payment_link"),
      },
      {
        count: 0,
        routeName: ICustomerManagementRouteEnum.PAT_TRANSACTIONS,
        title: t("module.customer_management.details.list_nav_pat"),
      },
    ]);

    function track(
      eventName: EventEnum,
      additionalData: Record<string, string> = {}
    ) {
      customerManagementTrack({
        // @ts-ignore
        analytics: vm?.proxy.$analytics,
        businessId: props.businessId,
        eventName,
        additionalData,
      });
    }

    onMounted(async () => {
      track(EventEnum.CUSTOMER_MANAGEMENT_VIEW_DETAILS);

      try {
        await getCustomerInfo();

        if (customerInfo.value === undefined) {
          return;
        }

        await getLoyaltyBalance(customerInfo.value.phoneNumber);
      } catch (e) {
        generalErrorToast();
        report(e);
      } finally {
        isLoading.value = false;
      }
    });

    return {
      t,
      css,
      isLoading,
      customerInfo,
      loyaltyBalance,
      averageSales,
      totalSales,
      averageRating,
      transactions,
      phoneBeautifier,
      tabTransactionsNavigation,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
